import { appWithTranslation } from 'next-i18next'
import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'

import { Providers } from '../providers'
import '../styles/globals.css'

import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import en from '../locales/en-US/common.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      'en-US': {
        translation: en
      },
      es: {
        translation: {}
      }
    },
    lng: 'es', // if you're using a language detector, do not define the lng option
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

function MyApp ({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const { locale } = useRouter()
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [i18n, locale])
  return (
    <Providers session={session}><Component {...pageProps} /></Providers>
  )
}

export default appWithTranslation(MyApp)
