import { createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useMemo } from 'react'

const httpLink = createHttpLink({
  uri: 'https://gql.aboga.me/v1/graphql'
})

export const useApollo = ({ token }: {token: unknown}) => {
  return useMemo(() => {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...(token
            ? {
                authorization: `Bearer ${token}`
              }
            : {})
        }
      }
    })

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    })
  }, [token])
}
