import { FC } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '@hooks/useApolloClient'
import { useSession, UseSessionOptions } from 'next-auth/react'

export const CustomApolloProvider: FC<UseSessionOptions<boolean>> = ({ children, ...options }) => {
  const { data } = useSession(options)
  const client = useApollo({ token: data?.token })
  return (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  )
}
