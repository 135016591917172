import type { FC } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'react-hot-toast'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDayjs from '@mui/lab/AdapterDayjs'

import theme from '@styles/theme'

import { CustomApolloProvider } from 'providers/ApolloProvider'

type ProvidersProps = {
  session: any
}

export const Providers: FC<ProvidersProps> = ({ session, children }) => (
  <SessionProvider session={session}>
    <CustomApolloProvider required>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Toaster/>
          {children}
        </ThemeProvider>
      </LocalizationProvider>
    </CustomApolloProvider>
  </SessionProvider>
)
