import { createTheme } from '@mui/material/styles'
import { red, purple } from '@mui/material/colors'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: purple[500]
    },
    secondary: {
      main: '#f44336'
    },
    error: {
      main: red.A400
    }
  }
})

export default theme
